






































































import Vue from 'vue';
import Component from 'vue-class-component';
import { FormData } from '@/repositories/apiClients.generated';
import { FeedbackApiClient } from '@/repositories';

@Component
export default class SupportForm extends Vue {
    loading = false;
    submitting = false;
    valid = true;
    feedbackStatus = 0;

    formData = new FormData({
        metadata: this.$route.query,
        name: '',
        email: '',
        phoneNumber: '',
        comments: ''
    });

    nameRules = [(v: string) => !v || v.length <= 100 || 'Name must be less than 100 characters'];
    emailRules = [
        (v: string) => !!v || 'E-mail is required',
        (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        (v: string) => !v || v.length <= 100 || 'Email must be less than 100 characters'
    ];
    phoneRules = [
        (v: string) => !v || v.length <= 31 || 'Phone must be less than 31 characters',
        (v: string) => /^[+]?[0-9 ()]{0,31}$/.test(v) || 'Phone number must only contain numbers, spaces or +'
    ];
    commentsRule = [(v: string) => !!v || 'A message is required', (v: string) => !!v || v.length <= 5000 || 'Message must be less than 5000 characters'];

    created(): void {
        this.$vuetify.theme.dark = false;
    }

    async submitForm() {
        try {
            this.submitting = true;
            var apiClient = new FeedbackApiClient();
            const response = await apiClient.post(this.formData);
            this.feedbackStatus = response.status;
            this.submitting = false;
        } catch (error: any) {
            this.feedbackStatus = error;
        }
    }
}
