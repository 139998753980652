import routes from '@/routes';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import VueRouter from 'vue-router';

export const router = async (): Promise<VueRouter> => {
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    const { default: VueRouter } = await import(/* webpackChunkName: "vue-router" */ 'vue-router');
    Vue.use(VueRouter);

    return new VueRouter({
        routes
    });
};

export default router;
